import { FaArrowAltCircleRight } from 'react-icons/fa'
import { Card } from '../components/ui/card'
import React from 'react'

 const HRPolicy = () => {
    return (
      <div className=''>
        <img src="/assets/images/hrpolicy_banner.jpg" alt="" className="w-full"/>
        <div className="wrapper">
          <div className='grid md:grid-cols-2 grid-cols-1 gap-10 my-10'>
            <img src="/assets/images/pol2.png" alt="" className='max-md:block hidden '/>
            <div className='mx-4'>
              <Card className="p-2 my-4 space-y-3">
                <p className='font-semibold text-lg'>Compliance Assurance:</p>
                <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Ensures company policies are in line with current labor laws and regulations.</p>
                <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Minimizes legal risks by maintaining up-to-date HR policies.</p>
                <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Provides guidance on regulatory changes and their impact on company policies.</p>
                <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Reduces the likelihood of compliance violations and penalties.</p>
              </Card>
              <Card className="p-2 my-4 space-y-3">
                <p className='font-semibold text-lg'>Policy Development:</p>
                <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Creates comprehensive HR policies tailored to the organization's needs.</p>
                <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Ensures policies are clear, concise, and easily understood by employees.</p>
                <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Aligns policies with organizational goals and culture.</p>
                <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Regularly reviews and updates policies to keep them relevant and effective.</p>
              </Card>
            </div>
            <img src="/assets/images/pol2.png" alt="" className='md:block hidden '/>
          </div>
          <div className='grid md:grid-cols-2 grid-cols-1 gap-10 my-10'>
            <img src="/assets/images/pol3.jpg" alt="" className="w-full rounded-lg"/>
            <div className='mx-4'>
              <Card className="p-2 space-y-3">
                <p className='font-semibold text-lg'>Employee Relations:</p>
                <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Facilitates fair and consistent policy application across the organization.</p>
                <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Promotes a positive work environment through well-defined policies.</p>
                <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Addresses employee concerns and grievances effectively.</p>
                <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Supports management in implementing policies that enhance employee satisfaction.</p>
              </Card>
              <Card className="p-2 my-4 space-y-3">
                <p className='font-semibold text-lg'>Training and Development:</p>
                <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Provides training on policy implementation and compliance.</p>
                <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Educates employees on their rights and responsibilities under company policies.</p>
                <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Ensures managers are equipped to enforce policies effectively.</p>
                <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Promotes continuous learning and adherence to best practices.</p>
              </Card>
            </div>
          </div>
        </div>
      </div>
    )
  }
  
export default HRPolicy