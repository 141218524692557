import { FaArrowAltCircleRight } from 'react-icons/fa'
import { Card } from '../components/ui/card'
import React from 'react'

const HROperational = () => {
  return (
    <div className=''>
      <img src="/assets/images/hroper.jpg" alt="" className="w-full"/>
      <div className="wrapper">
        <div className='grid md:grid-cols-2 grid-cols-1 gap-10 my-10'>
          <img src="/assets/images/op2.png" alt="" className='max-md:block hidden '/>
          <div className='mx-4'>
            <Card className="p-2 my-4 space-y-3">
              <p className='font-semibold text-lg'>Streamlined Processes:</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Optimizes HR workflows for increased efficiency and productivity.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Automates repetitive tasks, reducing manual workload and errors.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Enhances coordination between departments through integrated systems.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Improves response times and service delivery in HR operations.</p>
            </Card>
            <Card className="p-2 my-4 space-y-3">
              <p className='font-semibold text-lg'>Scalability:</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Adjusts HR services to meet the growing needs of your organization.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Supports expansion without the need for significant additional resources.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Ensures that HR operations can handle increased employee numbers smoothly.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Provides flexible solutions that adapt to business changes and demands.</p>
            </Card>
            <Card className="p-2 my-4 space-y-3">
              <p className='font-semibold text-lg'>Focus on Core Business:</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Allows management and staff to concentrate on primary business activities.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Frees up resources to invest in growth and innovation.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Reduces time spent on HR-related administrative tasks.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Enables strategic planning and decision-making without HR distractions.</p>
            </Card>
          </div>
          <img src="/assets/images/op2.png" alt="" className='md:block hidden '/>
        </div>
        <div className='grid md:grid-cols-2 grid-cols-1 gap-10 my-10'>
          <img src="/assets/images/op3.jpg" alt=""  className="w-full rounded-lg"/>
          <div className='mx-4'>
            <Card className="p-2 my-4 space-y-3">
              <p className='font-semibold text-lg space-y-4'>Access to Advanced Technology:</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Utilizes state-of-the-art HR software and systems.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Improves data management, reporting, and analytics capabilities.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Offers employee self-service portals for easy access to HR information.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Enhances data security and privacy with robust HR technology solutions.</p>
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HROperational