import { Route,RouterProvider,ScrollRestoration,createBrowserRouter,createRoutesFromElements, useRoutes,} from "react-router-dom";
import Home from "./Pages/Home";
import MainNavigation from "./Pages/MainNavigation";
import { ScrollTop } from "./components/shared/RootLayout/ScrollToTop";
import ContactUs from "./Pages/ContactUs";
import SubNavigation from "./Pages/SubNavigation";
import RecruitingServices from "./Pages/RecruitingServices";
import AboutUs from "./Pages/AboutUs";
import HROperational from "./Pages/HROperational";
import HRConsulting from "./Pages/HRConsulting";
import HRPolicy from "./Pages/HRPolicy";
import HRPerformance from "./Pages/HRPerformance";
import HRCompliance from "./Pages/HRCompliance";


const Rout = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<MainNavigation />}>
        <Route index element={<Home />} />
      </Route>
      <Route path="/"  element={<SubNavigation />}>
      <Route element={<ScrollTop />}>
        <Route path="contactus" element={<ContactUs />} />
        <Route path="hrconsulting" element={<HRConsulting />} />
        <Route path="hroperational" element={<HROperational />} />
        <Route path="hrcompliance" element={<HRCompliance />} />
        <Route path="hrpolicy" element={<HRPolicy />} />
        <Route path="performancemanagement" element={<HRPerformance />} />
        <Route path="recruitingservices" element={<RecruitingServices />} />
        <Route path="aboutus" element={<AboutUs />} />
      </Route>
      </Route>
    </Route>
  )
);



const App = () => {
  return <RouterProvider router={Rout} />;
}; 


export default App;
