import { FaArrowAltCircleRight } from 'react-icons/fa'
import { Card } from '../components/ui/card'
import React from 'react'

const HRCompliance = () => {
  return (
    <div className=''>
      <img src="/assets/images/hrcompl.jpg" alt="" className="w-full"/>
      <div className="wrapper">
        <div className='grid md:grid-cols-2 grid-cols-1 gap-10 my-10'>
          <img src="/assets/images/compl2.jpg" alt="" className='max-md:block hidden rounded-lg'/>
          <div className='mx-4'>
            <Card className="p-2 my-4 space-y-3">
              <p className='font-semibold text-lg '>Ensuring Compliance:</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Conducts thorough audits to identify potential compliance gaps.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Helps develop and implement compliant HR policies and procedures.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Keeps the organization updated with the latest labor laws and regulations.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Mitigates risks associated with non-compliance and regulatory breaches.</p>
            </Card>
            <Card className="p-2 my-4 space-y-3">
              <p className='font-semibold text-lg'>Expert Guidance:</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Provides expert advice on complex compliance issues.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Ensures adherence to industry-specific regulations and standards.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Offers training programs to educate employees on compliance matters.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Supports in handling compliance audits and inspections effectively.</p>
            </Card>
          </div>
          <img src="/assets/images/compl2.jpg" alt="" className='md:block hidden rounded-lg'/>
        </div>
        <div className='grid md:grid-cols-2 grid-cols-1 gap-10 my-10'>
          <img src="/assets/images/compl3.jpg" alt="" className="w-full rounded-lg"/>
          <div className='mx-4'>
            <Card className="p-2 my-4 space-y-3">
              <p className='font-semibold text-lg space-y-4'>Risk Management:</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Identifies and mitigates potential compliance risks proactively.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Develops risk management strategies tailored to the organization.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Implements robust internal controls to prevent compliance breaches.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Monitors and reviews compliance processes regularly to ensure effectiveness.</p>
            </Card>
            <Card className="p-2 my-4 space-y-3">
              <p className='font-semibold text-lg'>Regulatory Updates:</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Provides regular updates on changes in labor laws and regulations.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Ensures the organization remains compliant with new legal requirements.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Adapts HR policies to align with updated regulatory standards.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Educates and trains staff on new compliance obligations.</p>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HRCompliance;

