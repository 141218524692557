import { FaHandshakeSimple, FaRegHandPointRight } from 'react-icons/fa6'
import { ChooseUS, HomeServices, Steps, visaServices } from '../../../constants'
import React from 'react'
import { Card } from '../../../components/ui/card'
import { Slide } from 'react-awesome-reveal';
import CardComponent from './CardFlip';
import { RiSearchEyeLine } from "react-icons/ri";
import { IoHeartCircleOutline } from 'react-icons/io5';
import { FaBalanceScale } from 'react-icons/fa';

const HomeSub = () => {
    return (
        <div className='wrapper'>
       
            <div className=''>
                <h5 className='text-center font-bold text-4xl my-2 mb-10 '>OUR SERVICES</h5>
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1  gap-6">
                    {visaServices.map(vs => <CardComponent key={vs.id} services={vs} />)}
                </div>
            </div>
            <div className="my-4 wrapper">
                <h5 className='text-center font-bold text-4xl mt-10 mb-6'>Consulting Process Overview</h5>
                {Steps.map(step=><div key={step.id} className='grid md:grid-cols-2 md:gap-10 my-10'>
                    <p className="font-bold text-xl md:mt-10  max-md:block hidden my-4">{step.heading}</p>
                    <img src={step.img} alt="step.text" className='w-full max-md:block hidden rounded-lg' />
                <Slide direction="left" triggerOnce> {step.id % 2 !== 0 && <img src={step.img} alt="ser.text" className='md:block hidden rounded-lg' />}</Slide>
 
                    <div>
                    <p className="font-bold text-2xl md:mt-10 md:block hidden text-center my-4">{step.heading}</p>
                    <p className=" mb-4 text-justify my-1 leading-7">{step.text}</p>
                    </div>
                    <Slide direction="right" triggerOnce> {step.id % 2 === 0 && <img src={step.img} alt="ser.text" className='md:block hidden rounded-lg' />}</Slide>
                
                </div>)}
            </div>
            <div className="my-4">
                <h5 className='text-center font-bold text-4xl mb-6 '>Our Core values</h5>
                <div className='grid md:grid-cols-3 grid-cols-1 gap-4'>
                    <div className="flex flex-col gap-3 p-4  bg-gray-100 justify-center items-center">
                        <FaBalanceScale  size={48} />
                        <p className="text-blue-500 font-bold text-lg">Integrity</p>
                        <p>We uphold ethical and fearless practices, ensuring our words align with our actions.</p>
                    </div>
                    <div className="flex flex-col gap-3 p-4  bg-gray-100 justify-center items-center">
                        <RiSearchEyeLine  size={48} />
                        <p className="text-blue-500 font-bold text-lg">Transparency</p>
                        <p>We foster honest communication and provide accurate information openly.</p>
                    </div>
                    <div className="flex flex-col gap-3 p-4  bg-gray-100 justify-center items-center">
                        <FaHandshakeSimple  size={48} />
                        <p className="text-blue-500 font-bold text-lg">Relationship</p>
                        <p>Our approach is collaborative and cohesive, creating seamless and harmonious partnerships.</p>
                    </div>
                </div>
            </div>
          
            <div className="my-6">
                <h5 className='text-center font-bold text-4xl mt-10 mb-6'>Why Choose Us?</h5>
              <p className='my-2'><span className="font-semibold">Personalized service:</span> We understand that every client's situation is unique, so we provide personalized service tailored to each individual's needs. We take the time to understand your goals and requirements, and work with you to develop a customized immigration strategy that suits your needs.</p>
                <p className='my-2'><span className="font-semibold">Smooth and hassle-free process:</span> We take care of all the paperwork and procedures involved in the immigration process, ensuring a smooth and hassle-free experience for our clients. We guide you through the entire process, from initial consultation to visa application and beyond.</p>
                <p className='my-2'><span className="font-semibold">Affordable pricing:</span> We offer competitive pricing for our services, without compromising on the quality of our work. We believe that everyone should have access to professional immigration services, so we strive to keep our fees reasonable and transparent.</p>
                <p className='my-2'><span className="font-semibold ">Responsive communication:</span> We understand the importance of timely communication and keeping our clients informed throughout the process. We are always available to answer your questions and provide updates on your case, ensuring that you are kept informed every step of the way.</p>
            </div>
        </div>
    )
}

export default HomeSub