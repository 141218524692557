

export const subHeaderLinks = [
  {
    label: 'HOME',
    route: '/',
  },
  {
    label: 'SERVICES',
    route: '/services',
    subroutes:[{
      label: 'HR Consulting',
      route: '/hrconsulting',
    },
    {
      label: 'HR Regulatory Compliance Services',
      route: '/hrcompliance',
    },
    {
      label: 'Recruiting Services',
      route: '/recruitingservices',
    },
    {
      label: 'HR Operational Support',
      route: '/hroperational',
    },
    {
      label: 'HR Policy Advisory Services',
      route: '/hrpolicy',
    },
    {
      label: 'Strategic Performance Management Consulting',
      route: '/performancemanagement',
    },
  ]
  },
  {
    label: 'ABOUT US',
    route: '/aboutUs',
  },
    {
      label: 'CONTACT US',
      route: '/contactus',
    },
]

export const MobileLinks = [
  {
    label: 'Home',
    route: '/',
  },
  {
    label: 'HR Consulting',
    route: '/hrconsulting',
  },
  {
    label: 'HR Regulatory Compliance Services',
    route: '/hroutsourcing',
  },
  {
    label: 'Recruiting Services',
    route: '/recruitingservices',
  },
  {
    label: 'HR Operational Support',
    route: '/hroperational',
  },
  {
    label: 'HR Policy Advisory Services',
    route: '/hrpolicy',
  },
  {
    label: 'Strategic Performance Management Consulting',
    route: '/performancemanagement',
  },
{
      label: 'About Us',
      route: '/aboutUs',
    },
    {
      label: 'Contact Us',
      route: '/cONTACTUS',
    },

]

export const HomeServices=[
{id:1,  
  heading:"Moving skilled professionals to the UK, Europe, Canada & Dubai is what we do best",
  text: `TOM Universal is a registered corporation with the Indian government; TOM Universal Pvt Ltd offers a variety of immigration services to help both corporate and individual clients with their visa-related problems. We help small and medium-sized businesses find Indian talent and are experts in processing visas for skilled workers. Our clientele come from the IT sector.\n We also work with individual clients and offer in-depth support for all types of immigration. We can assist you in realizing your immigration aspirations, whether you are thinking about travelling briefly, moving abroad for school, or joining a relative. We will take the time to learn about your needs since we understand how essential it is to seek reliable guidance and specialized services when it comes to immigration, and we will take the time to understand your needs in order to provide the best possible solution at a competitive price.`,
  img:"/assets/visa.png"
},
{id:2,
  heading:"Avoid being struck. Choose the right visa for your needs.",
  text:"At TOM Universal, we take pleasure in assisting IT and healthcare professionals who wish to immigrate to the UK, Europe, or Canada with the goal of settling down there permanently. We take pride in providing the most individualised immigration service possible, adjusting our recommendations to your needs and making sure that your case has the best possible chance of being approved. We process applications quickly and effectively, making sure that our clients are informed at every step.\n We are committed and have in-depth knowledge of the requirements for immigration to the UK, Europe, and Canada. We recognise how crucial it is to seek reliable assistance and qualified services when it comes to immigration, so we will take the time to learn about your needs and offer the most suitable solution at a reasonable cost.\n To guarantee that the application is granted, it is critical that the appropriate documentation be here in place.",
  img:"/assets/visa_appr.png"
},
{id:3,
  heading:"The UK Health Care Visa as Care Assistant",
  text:"The UK Health Care Visa is a type of visa introduced by the UK government to support the recruitment of skilled healthcare professionals from outside the European Union (EU) and European Economic Area (EEA). The Health Care Visa is specifically designed to help non-UK citizens with qualifications in healthcare to work in the UK National Health Service (NHS) or the UK private healthcare sector.\nAs a Care Assistant, the UK Health Care Visa would allow you to work in the UK in a care home or residential setting, providing support to patients who require assistance with day-to-day activities such as personal care, mobility and social engagement. You would be required to have a relevant qualification in health and social care and a minimum of six months' experience working in a care setting.",
  img:"/assets/health_care.png"
},
{id:4,
  heading:"IT Professional",
  text:"The IT Professional visa is a type of visa designed by the UK government for highly skilled IT professionals who want to work in the UK. This visa category is part of the UK's Points-Based System, which assesses applicants based on their skills, experience, and qualifications.\nTo be eligible for an IT Professional visa, applicants must have a job offer from a UK employer that is on the list of eligible sponsors. They must also have a Certificate of Sponsorship from their employer and score enough points on the Points-Based System.\nThe Points-Based System takes into account several factors, including the applicant's qualifications, previous earnings, and English language proficiency. Applicants must score a minimum of 70 points to be eligible for the IT Professional visa.",
  img:"/assets/it_prof.png"
},
{id:5,
  heading:"Recruitment",
  text:"The leading destination for professionals seeking their perfect career in the United Kingdom. We understand that finding the right job can be a challenging and time-consuming process. That's why we're here to simplify it for you.\nAt Tom Universal, we pride ourselves on our extensive network of trusted employers spanning various industries. Whether you're an experienced professional looking for a senior executive role or a fresh graduate searching for your first opportunity, we have connections that match your aspirations.\nOur team of experienced recruiters and advanced algorithms work tirelessly to ensure that we deliver personalized job matches tailored to your unique skills, interests, and career goals. We believe that finding the right fit is crucial for long-term success and job satisfaction.\nWith our deep knowledge of the UK job market and industry insights, we stay ahead of the latest trends and opportunities. This expertise allows us to provide you with valuable guidance and support throughout your job search journey. We're committed to helping you navigate the competitive landscape and make informed career decisions.",
  img:"/assets/rec.jpg"
},
]

export const visaServices=[
{id:1,  
  heading:"HR Consulting",
  text: "HR consulting specializes in advising businesses on recruitment strategies, employee relations, and compliance with labor regulations. Consultants optimize HR processes to enhance organizational efficiency and foster a productive work environment aligned with company objectives.",
  img:"/assets/services/hrc.jpeg",
  route: '/hrconsulting',
},
{id:3,
  heading:"Recruiting Services",
  text:"Recruiting services offer tailored solutions for sourcing, screening, and hiring candidates, optimizing talent acquisition processes for organizations. Consultants provide expertise in recruitment strategies, candidate assessment, and onboarding to meet specific business needs efficiently.",
  img:"/assets/services/recruiting.jpg",
  route: '/recruitingservices',
},
{id:2,
  heading:"HR Regulatory Compliance Services",
  text: "HR regulatory compliance services ensure businesses adhere to labor laws, minimizing legal risks and promoting a fair workplace. Consultants provide expertise in policies, audits, and training to maintain compliance and support organizational integrity.",
  img:"/assets/services/HRMcompliance.jpeg",
  route: '/hrcompliance',
},
{id:5,
  heading:"Strategic Performance Management Consulting",
  text:"Strategic performance management consulting offers tailored solutions to enhance organizational effectiveness and achieve business goals. Consultants specialize in goal setting, performance measurement, and feedback systems to optimize productivity and align with strategic objectives.",
  img:"/assets/services/smc.png",
  route: '/performancemanagement',
},
{id:4,
  heading:"HR Policy Advisory Services",
  text:"HR policy advisory services specialize in crafting and implementing compliant HR policies that enhance employee relations and support organizational goals. Consultants provide strategic guidance to optimize policy frameworks and promote a positive workplace culture.",
  img:"/assets/services/hra.png",
  route: '/hrpolicy',
},
{id:6,
  heading:"HR Operational Support",
  text:"HR operational support ensures smooth daily operations through services like payroll management, benefits administration, and compliance oversight. Consultants streamline processes, resolve employee queries, and maintain regulatory adherence to optimize business efficiency.",
  img:"/assets/services/HRo.png",
  route: '/hroperational',
},
]

export const ChooseUS=[
{id:1,  
  heading:"Expertise:",
  text:" Our team of immigration providers has years of experience and expertise in helping clients obtain various types of visas, including VISA Switch to UK, General Skilled work visa Tier 2 VISA, Immigrant visas for skilled professionals. We stay up-to-date with the latest immigration laws and regulations to ensure that our clients receive the most accurate and reliable advice.",
  
},
{id:2,
  heading:"Personalized service:",
  text:" We understand that every client's situation is unique, so we provide personalized service tailored to each individual's needs. We take the time to understand your goals and requirements, and work with you to develop a customized immigration strategy that suits your needs."
},
{id:3,
  heading:"Smooth and hassle-free process:",
  text:" We take care of all the paperwork and procedures involved in the immigration process, ensuring a smooth and hassle-free experience for our clients. We guide you through the entire process, from initial consultation to visa application and beyond."
},
{id:4,
  heading:"Affordable pricing:",
  text:" We offer competitive pricing for our services, without compromising on the quality of our work. We believe that everyone should have access to professional immigration services, so we strive to keep our fees reasonable and transparent."
},
{id:5,
  heading:"Responsive communication:",
  text:" We understand the importance of timely communication and keeping our clients informed throughout the process. We are always available to answer your questions and provide updates on your case, ensuring that you are kept informed every step of the way."
},
]

 export const Steps = [
    {
      "id": 1,
      "heading": "Initial Assessment",
      "text": "Conducting an initial assessment is crucial to understand the client's current challenges, goals, and specific needs comprehensively. This phase involves gathering data through interviews, surveys, and analysis of existing processes. It aims to uncover underlying issues and opportunities for improvement, setting the stage for developing tailored solutions. The assessment process includes engaging with key stakeholders to gather insights and perspectives, ensuring a holistic understanding of the organization's dynamics. By identifying strengths and weaknesses, this step enables consultants to propose strategies that address the client's unique requirements effectively. Ultimately, the initial assessment phase lays a solid foundation for a collaborative partnership focused on achieving sustainable outcomes.",
      "img": "/assets/images/home_img1.jpg"
    },
    {
      "id": 2,
      "heading": "Strategy Development",
      "text": "Developing a comprehensive strategy is key to translating assessment insights into actionable plans that drive organizational success. This phase involves synthesizing data, defining clear objectives, and outlining strategic initiatives. Consultants collaborate with the client to prioritize goals, allocate resources, and establish timelines. The strategy development process includes designing frameworks for monitoring progress and evaluating outcomes, ensuring alignment with long-term business objectives. By identifying key performance indicators (KPIs) and milestones, consultants create a roadmap that guides implementation and measures success. This strategic alignment empowers organizations to navigate challenges proactively and capitalize on opportunities for growth and innovation.",
      "img": "/assets/images/home_img2.jpg"
    },
    {
      "id": 3,
      "heading": "Implementation",
      "text": "Implementing strategies involves deploying resources, tools, and expertise to execute planned initiatives effectively. This phase focuses on project management, team collaboration, and leveraging technology to streamline processes. Consultants work closely with stakeholders to ensure clarity of roles, responsibilities, and expectations. They monitor progress closely, making real-time adjustments to optimize performance and address emerging challenges. Implementation emphasizes communication and transparency, fostering a culture of accountability and continuous improvement. By proactively managing risks and obstacles, consultants facilitate smooth execution and lay the groundwork for sustainable organizational change.",
      "img": "/assets/images/home_img3.jpg"
    },
    {
      "id": 4,
      "heading": "Monitoring and Adjustment",
      "text": "Monitoring progress and outcomes is essential to track the effectiveness of implemented strategies and initiatives. This phase involves collecting and analyzing data, evaluating key performance indicators (KPIs), and conducting regular performance reviews. Consultants collaborate with stakeholders to identify trends, insights, and areas for improvement. They make data-driven decisions and adjustments to ensure alignment with organizational goals and objectives. Continuous monitoring fosters agility and responsiveness, enabling organizations to adapt to changing market dynamics and evolving business needs effectively. By maintaining transparency and open communication, consultants promote a culture of learning and innovation.",
      "img": "/assets/images/home_img4.png"
    },
    {
      "id": 5,
      "heading": "Evaluation",
      "text": "Evaluating the impact and effectiveness of implemented solutions is critical to driving continuous improvement and sustainable outcomes. This phase involves gathering feedback from stakeholders, conducting surveys, and assessing performance against predefined metrics. Consultants analyze results, identify successes, challenges, and areas for refinement. They leverage insights to refine strategies, adjust tactics, and optimize processes for enhanced efficiency and effectiveness. Evaluation promotes accountability and transparency, demonstrating tangible results and ROI to stakeholders. By fostering a culture of evaluation and learning, consultants enable organizations to evolve and thrive in a competitive landscape.",
      "img": "/assets/images/home_img5.jpg"
    },
    {
      "id": 6,
      "heading": "Ongoing Support",
      "text": "Providing ongoing support is integral to sustaining improvements and addressing emerging challenges post-implementation. This phase involves offering guidance, training, and mentorship to empower teams and reinforce new behaviors and practices. Consultants facilitate knowledge transfer, ensuring stakeholders are equipped to maintain momentum and drive organizational success. They remain accessible for troubleshooting, strategy refinement, and proactive intervention as needed. Ongoing support promotes continuity and resilience, enabling organizations to navigate uncertainties and seize opportunities effectively. By fostering long-term partnerships and commitment, consultants contribute to sustained growth and operational excellence.",
      "img": "/assets/images/home_img6.jpg"
    }
  ];
  



