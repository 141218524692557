// CardComponent.jsx
import { Card } from '../../../components/ui/card'
import React, { useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import { FaArrowCircleRight } from 'react-icons/fa';
import { FaAnglesRight } from 'react-icons/fa6';
import { Link } from 'react-router-dom';



const CardComponent = ({ services }: any) => {
    const [isFlipped, setIsFlipped] = useState(false);

    const handleMouseEnter = () => {
        setIsFlipped(true);
    };

    const handleMouseLeave = () => {
        setIsFlipped(false);
    };

    return (
        <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal" flipSpeedBackToFront={1.5}
            flipSpeedFrontToBack={1.5}>
            <Card
                className="w-full h-60 cursor-pointer transition-transform duration-500 border-2 "
               /*  onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave} */
                onClick={handleMouseEnter}
            >
                <img src={services.img} alt="country" className='w-full h-full rounded-lg' />
                <div  className="absolute bottom-0 w-full flex justify-center items-center bg-opacity-60 bg-gray-700 py-2 rounded-b-lg">
                    <FaArrowCircleRight className="text-white text-2xl" />
                </div>
            </Card>

            <Card
                className="w-full h-60 cursor-pointer transition-transform duration-500 border-2 border-gray-600 p-1"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div className="flex flex-col gap-1">
                    <p className="font-semibold">{services.heading}</p>
                    <p >{services.text}</p>
                    <Link to={services.route} className='hover:cursor-pointer hover:text-blue-600 flex gap-1 items-center my-2'>Read more <FaAnglesRight /></Link>
                </div>
            </Card>
        </ReactCardFlip>
    );
};



export default CardComponent;
