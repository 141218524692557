import { FaArrowAltCircleRight } from 'react-icons/fa'
import { Card } from '../components/ui/card'
import React from 'react'

const HRPerformance = () => {
  return (
    <div className=''>
      <img src="/assets/images/perfmng.jpeg" alt="hr" className="w-full"/>
      <div className="wrapper">
        <div className='grid md:grid-cols-2 grid-cols-1 gap-10 my-10'>
          <img src="/assets/images/p2.jpg" alt="" className='max-md:block hidden rounded-lg'/>
          <div className='mx-4'>
            <Card className="p-2 my-4 space-y-3">
              <p className='font-semibold text-lg'>Goal Alignment:</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Ensures that individual and team goals are aligned with organizational objectives.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Facilitates the setting of clear, measurable performance goals.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Promotes a culture of accountability and high performance.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Links performance outcomes to compensation and rewards.</p>
            </Card>
            <Card className="p-2 my-4 space-y-3">
              <p className='font-semibold text-lg'>Performance Monitoring:</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Provides continuous tracking and monitoring of employee performance.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Utilizes performance metrics and KPIs to assess effectiveness.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Enables timely feedback and course correction.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Helps identify and address performance gaps proactively.</p>
            </Card>
          </div>
          <img src="/assets/images/p2.jpg" alt="" className='md:block hidden rounded-lg'/>
        </div>
        <div className='grid md:grid-cols-2 grid-cols-1 gap-10 my-10'>
          <img src="/assets/images/empdev.png" alt="" className="rounded-lg"/>
          <div className='mx-4'>
            <Card className="p-2 my-4 space-y-3">
              <p className='font-semibold text-lg'>Employee Development:</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Focuses on continuous learning and professional growth.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Provides coaching and mentoring to enhance skills and capabilities.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Supports career advancement through tailored development plans.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Encourages a culture of self-improvement and excellence.</p>
            </Card>
            <Card className="p-2 my-4 space-y-3">
              <p className='font-semibold text-lg'>Data-Driven Insights:</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Leverages analytics to inform performance management strategies.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Uses data to identify trends and make evidence-based decisions.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Supports predictive modeling to forecast performance outcomes.</p>
              <p className='flex gap-1 items-center'><FaArrowAltCircleRight />Provides insights into workforce productivity and effectiveness.</p>
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HRPerformance