import { useForm } from 'react-hook-form'
import { Card } from '../components/ui/card'
import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { Button } from "../components/ui/button"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../components/ui/form"
import { Input } from "../components/ui/input"
import { FaEnvelope, FaPhone } from 'react-icons/fa6'
import { MdMyLocation } from "react-icons/md";
import { IoLocation } from 'react-icons/io5'
import { Textarea } from '../components/ui/textarea'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css";

const formSchema = z.object({
  fullName: z.string().min(3, { message: 'Full name is required with 3 characters' }),
  email: z.string().email({ message: 'Invalid email address' }),
    mobile: z.string().length(10, { message: 'Mobile number must be exactly 10 characters long' }),
  message: z.string().min(0, { message: 'Message must be at least 0 characters long' }).max(200, { message: 'Message must not exceed 200 characters' }),
});

const ContactUs = () => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      fullName: "", email: "", message: "", mobile: ""
    },
  })

  const onSubmit = async(values: z.infer<typeof formSchema>) =>{
    try {
      const response = await axios.post("https://tomuniversal.com/tomapi/sendVisitorInfo", values); 
      console.log(response.data);
      
      if (response.data) {
        toast.success("Contact details sent successfully!");
      } else {
        toast.error("Failed to send contact details.");
      }
    } catch (error) {
      toast.error("An error occurred while sending the contact details.");
    }
  }
  
  return (<>
    <div className='wrapper grid md:grid-cols-2 grid-cols-1 gap-6'>
      <div className=' w-full flex flex-col  '>
        <img src="/assets/images/conatct.png" alt="contact" className='w-full rounded-lg max-md:hidden'/>
        <div className='space-y-6 my-6'>
      <p className='text-xl font-semibold border-b-2 border-blue-500 pb-2'>Contact Details:</p>
      <div className='flex flex-col md:flex-row justify-between gap-4'>
        <div className='flex items-center gap-2 p-4 bg-blue-100 border-l-4 border-blue-500 rounded-md shadow-lg'>
          <MdMyLocation size={20} className='text-blue-700 w-10' />
          <p className='text-gray-700'>647, 100feet road, Benemangla, Indiranagar, Bangalore-562110 Karnataka.</p>
        </div>
        <div className='flex items-center gap-2 p-4 bg-purple-100 border-l-4 border-purple-500 rounded-md shadow-lg'>
         <IoLocation size={20} className='text-purple-700 w-10' />
          <p className='text-gray-700'>D 001, trendset winz, apartment, Nanakramguda, Hyderabad, Telangana-500008.</p>
        </div>
      </div>
      <div className='flex flex-col md:flex-row justify-between  gap-4'>
        <div className='flex items-center md:max-w-[290px] w-full gap-2 p-4 bg-yellow-100 border-l-4 border-yellow-500 rounded-md shadow-lg'>
          <FaPhone size={20} className='text-yellow-700' />
          <span className='text-gray-700 hover:text-blue-500 '>+91 8121872017</span>
        </div>
        <div className='flex items-center gap-2 w-full p-4 bg-green-100 border-l-4 border-green-500 rounded-md shadow-lg'>
          <FaEnvelope size={24} className='text-green-700' />
          <span className='text-gray-700 hover:text-blue-500 '> info@tomuniversal.com </span>
        </div>
      </div>
    </div>
        </div>
      <div>
        <p className='text-xl font-bold'>CONTACT US</p>
        <div className='max-w-xl flex justify-center my-4'>
          <Card className='w-full  p-3'>
            <Form {...form}>
              <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
                <FormField
                  control={form.control}
                  name="fullName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Full Name*</FormLabel>
                      <FormControl>
                        <Input placeholder="Name" {...field} className='input-field' />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="email"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Eamil*</FormLabel>
                      <FormControl>
                        <Input placeholder="Email" {...field} className='input-field' />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="mobile"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Mobile*</FormLabel>
                      <FormControl>
                        <Input placeholder="Mobile" {...field} className='input-field' />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="message"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Message</FormLabel>
                      <FormControl>
                        <Textarea placeholder="Message" {...field} className='input-field' />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <Button type="submit" className='headermenu w-[100px]'>Submit</Button>
              </form>
            </Form>
          </Card>
        </div>
      </div>

    </div>
    <ToastContainer/>
    </>
  )
}

export default ContactUs