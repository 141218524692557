import Header from "../components/shared/RootLayout/Header";
import { Outlet } from "react-router-dom";
import Footer from "../components/shared/RootLayout/Footer";


const MainNavigation = () => {
  return (
    <div className="flex flex-col relative font-display">
      <Header />
      <div className="min-h-[500px]">
      <Outlet />
      </div>
      <Footer/>
    </div>
  );
};

export default MainNavigation;
